import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Link } from 'gatsby';
import hexToRgba from 'hex-to-rgba';
import { kebabCase } from 'lodash-es';

const Tag = styled(Link)`
  display: inline-block;
  padding: 4px 15px;
  color: ${props => hexToRgba(props.theme.primaryColor, 0.6)};
  background: ${props => hexToRgba(props.theme.primaryColor, 0.05)};
  border-radius: 3px;
  text-decoration: none;

  transition: all 300ms;

  :hover {
    background: ${props => hexToRgba(props.theme.primaryColor, 0.08)};
  }
`;

const TagLink = ({ tag }) => <Tag to={`/tags/${kebabCase(tag)}`}>{tag}</Tag>;

TagLink.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default withTheme(TagLink);
